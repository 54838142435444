<template>
  <div class="api-keys-table" ref="agGridTableContainer">
    <el-table :data="rowData" :height="tableHeight" @sort-change="sortHandler">
      <el-table-column
        v-for="(column, index) in columnDefs"
        :key="index"
        :label="column.label"
        :prop="column.field"
        :min-width="column.minWidth"
        :width="column.width"
        :sortable="column.sortable"
        :align="column.align"
      >
        <template slot-scope="scope">
          <component
            :is="column.component"
            :cellValue="scope.row"
            :field="column.field"
            :enableEdit="column.enableEdit"
            :enableCopy="column.enableCopy"
            :onlyNumbers="column.onlyNumbers"
            @keyUpdated="updateKey"
          />
        </template>
      </el-table-column>
      <el-table-column :width="17" align="center">
        <template slot-scope="scope">
          <button class="delete-key-button" @click="deleteKey(scope.row)">
            <i class="ion ion-trash-outline"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import "@/assets/scss/components/agGridStyleOverride.scss";
import OnResize from "../../../../Extend/onResize";
import BackgroundTenderCell from "./P44BacgkrdTenderCell.vue";
import TextHandlingCell from "./TextHandlingCell.vue";
import StatusCell from "./StatusCell.vue";

export default {
  name: "ApiKeysTable",
  props: {
    apiKeys: {
      type: Array,
      default: () => [],
    },
    updating: Boolean,
    allDataHasBeenLoaded: Boolean,
  },
  mixins: [OnResize],
  data() {
    return {
      tableHeight: null,
      tableContainer: null,
      tableContainerHeight: null,
      rowsContainer: null,
      rowData: null,
      columnDefs: [
        {
          label: "Company Name",
          field: "company_name",
          minWidth: 150,
          component: TextHandlingCell,
          sortable: "custom",
          enableCopy: true,
        },
        {
          label: "Account Email",
          field: "contact_email",
          width: 150,
          component: TextHandlingCell,
          enableCopy: true,
          enableEdit: true,
        },
        {
          label: "Status",
          field: "is_active",
          width: 80,
          component: StatusCell,
        },
        {
          label: "P44 Timeout",
          field: "p44_timeout",
          width: 70,
          component: TextHandlingCell,
          enableEdit: true,
          onlyNumbers: true,
          align: "center",
        },
        {
          label: "P44 Background Tender",
          field: "p44_background_tender",
          width: 118,
          component: BackgroundTenderCell,
        },
        {
          label: "Webhook Status",
          width: 118,
          field: "webhook_is_active",
          component: BackgroundTenderCell,
        },
        {
          label: "Webhook URL",
          width: 220,
          field: "webhook_url",
          component: TextHandlingCell,
          enableCopy: true,
          enableEdit: true,
          align: "center",
        },
        {
          label: "Api Key",
          field: "value",
          component: TextHandlingCell,
          enableCopy: true,
        },
        {
          label: "Requests Limit",
          field: "requests_limit",
          width: 80,
          component: TextHandlingCell,
          enableEdit: true,
          onlyNumbers: true,
          align: "center",
        },
        {
          label: "Creation Date",
          field: "create_at",
          width: 125,
          component: TextHandlingCell,
          sortable: "custom",
          align: "center",
        },
      ],
    };
  },
  async mounted() {
    await this.sleep(0);
    this.tableContainer = document.querySelector(".el-table__body-wrapper");
    this.tableContainerHeight = this.tableContainer.clientHeight;
    this.tableContainer.addEventListener("scroll", this.infiniteScrollHandler);
    this.rowsContainer = document.querySelector(".el-table__body");
  },
  watch: {
    screenWidth() {
      const clientHeight = window.innerHeight;
      this.tableHeight = clientHeight - 250;
    },
    apiKeys: {
      immediate: true,
      deep: true,
      async handler(value) {
        this.rowData = value.map((item) => {
          const isoDate = new Date(item.create_at).toISOString();
          const newItem = {
            ...item,
            company_name: item.account.company_name,
            create_at: dayjs(isoDate).format("YYYY-MM-DD"),
          };
          return newItem;
        });
      },
    },
  },
  methods: {
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    infiniteScrollHandler() {
      if (this.allDataHasBeenLoaded || this.updating) {
        return;
      }
      const elementTableHeight = this.rowsContainer.clientHeight;
      const currentScrollPosition = this.tableContainer.scrollTop;
      if (currentScrollPosition + this.tableContainerHeight < elementTableHeight - 20) {
        return;
      }
      this.tableContainer.scrollTop = currentScrollPosition - 30;
      this.$emit("loadMoreItems");
    },
    sortHandler(event) {
      if (event.order === "ascending") {
        if (event.prop === "create_at") {
          this.$emit("sort", { order_by: "create_at" });
        } else if (event.prop === "company_name") {
          this.$emit("sort", { order_by: "account__company_name" });
        }
        return;
      }
      if (event.order === "descending") {
        if (event.prop === "create_at") {
          this.$emit("sort", { order_by: "-create_at" });
        } else if (event.prop === "company_name") {
          this.$emit("sort", { order_by: "-account__company_name" });
        }
        return;
      }
      this.$emit("sort", { order_by: null });
    },
    updateKey(payload) {
      this.$emit("updateApiKey", payload);
    },
    deleteKey(payload) {
      this.$emit("deleteApiKey", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-key-button {
  color: $color-remove-inactive-button;
  padding: 0px;
  background: none;
  border: none;
  font-size: 16px;
}
.api-keys-table {
  @include table-design(4);
}
</style>
