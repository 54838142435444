<template>
  <div class="api-keys">
    <div class="api-keys__back" @click="changeView('Settings')">
      <i class="ion ion-chevron-back-outline"></i>
      <span>{{ $t("settings.back") }}</span>
    </div>
    <div class="api-keys__elements-container" v-loading="updating">
      <b-overlay :show="loading" class="overlay-div" rounded="sm">
        <div class="api-keys__buttons-toolbar">
          <div class="keys-searcher" :class="{ 'searcher-disabled': !apiKeys.length }">
            <i class="ion ion-search-outline"></i>
            <input
              type="text"
              class="keys-searcher__searcher"
              v-model="keysSearcher"
              placeholder="Search a company..."
              @input="debouncerHandler($event.target.value)"
              :disabled="!apiKeys.length"
            />
            <div class="keys-searcher__spinner" v-if="searchingCompanies">
              <div class="keys-searcher__spinner-icon"></div>
            </div>
            <div
              @click="(keysSearcher = ''), (filteredApiKeys = [...apiKeys])"
              class="keys-searcher__delete-button"
              v-if="!searchingCompanies && keysSearcher !== ''"
            >
              <i class="ion ion-close-outline"></i>
            </div>
          </div>
          <CompanyKeyGeneratorModal
            @generateNewApiKey="generateNewApiKey"
            v-model="showGeneratorKeyModal"
          />
          <a class="documentation-link" href="https://docs.webservices.goglt.com/" target="_blank"
            >API Docs <i class="ion ion-open-outline"></i
          ></a>
        </div>
        <ApiKeysTable
          v-if="apiKeys.length"
          :apiKeys="filteredApiKeys"
          :updating="updating"
          :allDataHasBeenLoaded="allDataHasBeenLoaded"
          @loadMoreItems="loadMoreItems"
          @sort="sortHandler"
          @updateApiKey="updateApiKey"
          @deleteApiKey="deleteApiKey"
        >
        </ApiKeysTable>
        <template #overlay>
          <div class="loading" v-show="loading">
            <b-icon icon="truck" font-scale="3" animation="cylon" class="loading__text"></b-icon>
            <p class="loading__text">{{ $t("loading") }}...</p>
          </div>
        </template>
      </b-overlay>
      <div class="api-keys__not-found" v-if="!loading && !updating && apiKeys.length === 0">
        <i class="ion ion-file-tray-outline api-keys__not-found-icon"></i>
        <p>No Api Keys found</p>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/Debounce";
import { generalMessage } from "../../../utils/ErrorMessages";
import ApiKeysTable from "./components/ApiKeysTable.vue";
import CompanyKeyGeneratorModal from "./components/ApiKeyGenerator.vue";

export default {
  name: "Keys",
  components: {
    ApiKeysTable,
    CompanyKeyGeneratorModal,
  },
  data() {
    return {
      loading: true,
      updating: false,
      searchingCompanies: false,
      showGeneratorKeyModal: false,
      skip: 0,
      limit: 100,
      order_by: null,
      allDataHasBeenLoaded: false,
      keysSearcher: "",
      debouncerHandler: null,
      filteredApiKeys: [],
    };
  },
  async created() {
    this.debouncerHandler = debounce((value) => this.filterKeys(value), 300);
    const apiKeys = await this.getApiKeys();
    this.apiKeys = [...apiKeys];
    this.filteredApiKeys = [...this.apiKeys];
    this.loading = false;
  },
  beforeDestroy() {
    this.apiKeys = [];
  },
  computed: {
    apiKeys: {
      get() {
        return this.$store.getters["apiKey/getAllApiKeys"];
      },
      set(value) {
        this.$store.commit("apiKey/setAllApiKeys", value);
      },
    },
  },
  methods: {
    async getApiKeys(params) {
      if (this.allDataHasBeenLoaded) {
        return [];
      }
      const response = await this.$store.dispatch("apiKey/getAllApiKeys", {
        skip: this.skip,
        limit: this.limit,
        order_by: this.order_by,
        ...(params || {}),
      });
      const apiKeys = response.map((item) => ({
        ...item,
        eyeIcon: "ion ion-eye-off-outline",
      }));
      if (!response.length || response.length < this.limit) {
        this.allDataHasBeenLoaded = true;
      }
      return apiKeys;
    },
    async updateApiKey(payload) {
      this.updating = true;
      await this.$store.dispatch("apiKey/updateStatusApiKey", {
        id: payload.id,
        body: { [payload.key]: payload.value },
      });
      this.$store.commit("apiKey/modifyApiKey", payload);
      const keyIndex = this.apiKeys.findIndex((item) => item.id === payload.id);
      this.apiKeys[keyIndex] = payload;
      this.filteredApiKeys = [...this.apiKeys];
      this.updating = false;
    },
    async deleteApiKey(payload) {
      const deleteConfirmation = await this.swal({
        title: "Do you want to delete this key permanently?",
        icon: "warning",
        buttons: true,
      });
      if (!deleteConfirmation) {
        return;
      }
      this.updating = true;
      this.resetVariables();
      await this.$store.dispatch("apiKey/deleteApiKey", payload.id);
      this.$message({
        type: "success",
        message: "Key successfully deleted",
        duration: 3000,
      });
      this.apiKeys = await this.getApiKeys();
      this.filteredApiKeys = [...this.apiKeys];
      this.updating = false;
    },
    async filterKeys(value) {
      const query = value.trim().toLowerCase();
      if (query.length < 3) {
        this.filteredApiKeys = [...this.apiKeys];
        return;
      }
      this.searchingCompanies = true;
      this.filteredApiKeys = this.apiKeys.filter((item) => {
        const companyName = item.account.company_name.toLowerCase();
        const acountEmail = item.contact_email.toLowerCase();
        return companyName.includes(query) || acountEmail.includes(query);
      });
      if (!this.filteredApiKeys.length) {
        this.filteredApiKeys = await this.searchKeys(query);
      }
      this.searchingCompanies = false;
    },
    async searchKeys(query) {
      this.resetVariables();
      const params = {
        search: query,
      };
      return this.getApiKeys(params);
    },
    async loadMoreItems() {
      this.updating = true;
      this.skip += this.limit;
      const apiKeys = await this.getApiKeys();
      this.apiKeys = [...this.apiKeys, ...apiKeys];
      this.filteredApiKeys = [...this.apiKeys];
      this.updating = false;
    },
    async sortHandler(params) {
      this.resetVariables();
      this.updating = true;
      this.order_by = params.order_by;
      this.apiKeys = await this.getApiKeys();
      this.filteredApiKeys = [...this.apiKeys];
      this.updating = false;
    },
    resetVariables() {
      this.skip = 0;
      this.limit = 100;
      this.allDataHasBeenLoaded = false;
    },
    async generateNewApiKey(data) {
      if (data) {
        generalMessage("New key generated");
        data.eyeIcon = "ion ion-eye-off-outline";
        data.account = {
          company_name: data.company_name.toUpperCase(),
        };
        this.apiKeys.unshift(data);
        this.filteredApiKeys = [...this.apiKeys];
      }
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.documentation-link {
  @include font-button-text;
  color: $color-secondary-button-text;
  outline: 0;
  padding: 5px 1%;
  width: auto;
  font-weight: 400;
  i {
    font-size: 16px;
  }
}

.api-keys {
  padding: 0px 5px;
  padding-top: 2%;
  padding-bottom: 1px;
  &__buttons-toolbar {
    margin: 20px 0px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__elements-container {
    margin: 0px 10px;
  }
  &__back {
    display: flex;
    align-items: center;
    margin-left: 1%;
    @include font-small-standart-text;
    font-weight: bold;
    width: 160px;
    cursor: pointer;
    i {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  &__not-found {
    color: $color-primary-company;
    margin-top: 200px;
    p {
      margin-bottom: 0;
    }
  }
  &__not-found-icon {
    font-size: 50px;
  }
}

.loading {
  margin-top: 450px;
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

.wrapper {
  display: flex;
}

.keys-searcher {
  position: relative;
  display: flex;
  width: 30%;
  min-width: 200px;
  height: 40px;
  padding: 6px;
  border-radius: 15px;
  border: 1px solid $color-border-container;
  @media (max-width: 500px) {
    &:nth-of-type(1) {
      order: 3;
    }
  }
  &__searcher {
    width: 100%;
    margin-left: 5px;
    padding-right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    letter-spacing: 0px;
    color: $color-primary-company;
  }
  &__spinner {
    color: #737373;
    position: absolute;
    top: 25%;
    right: 2%;
  }
  &__spinner-icon {
    height: 16px;
    width: 16px;
    color: #5a5a5a;
    position: relative;
    display: inline-block;
    border: 3px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite;
  }
  &__delete-button {
    cursor: pointer;
    color: #737373;
    position: absolute;
    top: 20%;
    right: 2%;
  }
}

.searcher-disabled {
  background-color: #efefef;
}

::v-deep .bg-light {
  background-color: $color-white !important;
}

::v-deep {
  .rc-searcher-container {
    font-size: 14.5px;
  }
}
</style>
