<template>
  <div class="keys-modal">
    <button class="keys-modal__button" @click="showModal = true">Generate key</button>
    <CustomModal
      v-model="showModal"
      size="md"
      :centered="true"
      :showOverlay="sendingRequest"
      :closeOnBackDrop="false"
      :closeOnEsc="false"
      modalTitle="Generate Company Key"
    >
      <template #modal-content>
        <div class="keys-modal__content">
          <p class="keys-modal__instruction">
            To generate a new api key, please search and choose a company. Also, provide a contact
            email.
          </p>
          <AutoComplete
            label="Type a company name"
            class="keys-modal__input keys-modal__input--searcher"
            :suggestions="companiesList"
            :requestLength="3"
            :requiredField="true"
            :loading="loadingSearch"
            @search="searchText($event.query)"
            v-model="formData.selectedCompany"
          />
          <RCInput
            :class="emptyFields['contactEmail'] ? 'missing-field' : ''"
            class="keys-modal__input"
            v-model="formData.contactEmail"
            :requiredField="true"
            label="Contact email"
          />
          <div class="webhook">
            <div class="webhook__status">
              <b-form-checkbox class="document-item__checkbox" v-model="formData.webhookStatus" />
              <span> Webhook </span>
            </div>
            <div class="webhook__url">
              <RCInput
                :class="emptyFields['webhookUrl'] ? 'missing-field' : ''"
                class="keys-modal__input"
                v-model="formData.webhookUrl"
                :requiredField="formData.webhookStatus ? true : false"
                label="Webhook Url"
              />
            </div>
          </div>
        </div>
        <div class="button-toolbar">
          <button class="button-toolbar__cancel" @click="showModal = false">
            {{ $t("userManagement.cancel") }}
          </button>
          <button class="button-toolbar__save" @click="generateNewApiKey()">
            {{ $t("userManagement.save") }}
          </button>
        </div>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import AutoComplete from "@/components/AutoComplete.vue";
import CustomModal from "../../../../components/CustomModal.vue";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import EmailValidation from "../../../../utils/EmailValidation";

export default {
  name: "CompanyKeyGenerator",
  components: {
    CustomModal,
    RCInput,
    AutoComplete,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      sendingRequest: false,
      showModal: this.value,
      companiesList: [{ options: [] }],
      formData: {
        selectedCompany: null,
        contactEmail: "",
        webhookStatus: false,
        webhookUrl: "",
      },
      emptyFields: {
        selectedCompany: false,
        contactEmail: false,
        webhookUrl: false,
      },
      loadingSearch: false,
    };
  },
  methods: {
    async searchText(query) {
      if (!query || query.length < 3) {
        return;
      }
      const data = {
        search: query,
      };
      this.loadingSearch = true;
      const response = await this.searchAccounts(data);
      this.loadingSearch = false;
      const modifiedResponse = response.map((item) => ({
        label: item.company_name,
        ...item,
      }));
      this.companiesList[0].options = [...modifiedResponse];
    },
    async searchAccounts(search) {
      const accountsResult = await this.$store.dispatch("account/getSearchAccounts", search);
      return accountsResult;
    },
    validateFields() {
      const objetData = { ...this.formData };
      if (!this.formData.webhookStatus) {
        delete objetData.webhookStatus;
        delete objetData.webhookUrl;
      }
      const validate = ValidateEmptyFields(objetData);
      this.resetEmptyFieldsValues();
      validate.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      return validate;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    validateEmail() {
      const isValidEmail = EmailValidation(this.formData.contactEmail);
      if (!isValidEmail) {
        this.missingFieldsMessage("Invalid email", "Please provide a valid email");
      }
      return isValidEmail;
    },
    async generateNewApiKey() {
      const { validation } = this.validateFields();
      if (!validation) {
        this.setEmptyFields();
        this.missingFieldsMessage();
        return;
      }
      const validateEmail = this.validateEmail();
      if (!validateEmail) {
        return;
      }
      const validateURL = this.validateUrlString(this.formData.webhookUrl);
      if (!validateURL) {
        this.missingFieldsMessage("Invalid URL", "Please provide a valid url webhook");
        return;
      }
      this.sendingRequest = true;
      const newApiKey = await this.$store.dispatch("apiKey/createCompanyApiKey", {
        account_id: this.formData.selectedCompany.id,
        contact_email: this.formData.contactEmail,
        company_name: this.formData.selectedCompany.company_name,
        webhook_is_active: this.formData.webhookStatus,
        webhook_url: this.formData.webhookUrl,
      });
      this.sendingRequest = false;
      this.$emit("generateNewApiKey", {
        ...newApiKey,
        p44_timeout: 45,
        p44_background_tender: false,
        requests_limit: 200,
      });
      this.formData.selectedCompany = null;
      this.formData.contactEmail = "";
      this.formData.webhookUrl = "";
      this.formData.webhookStatus = false;
      this.showModal = false;
      this.emptyFields = {
        selectedCompany: false,
        contactEmail: false,
        webhookUrl: false,
      };
    },
    validateUrlString(url) {
      if (this.formData.webhookStatus) {
        return /^https:\/\//.test(url);
      }
      return true;
    },
    setEmptyFields() {
      this.emptyFields = {
        selectedCompany: this.formData.selectedCompany === null,
        contactEmail: this.formData.contactEmail.length < 1,
        webhookUrl:
          this.formData.webhookStatus &&
          this.formData.webhookUrl === "" &&
          !this.validateUrlString(this.formData.webhookUrl),
      };
    },
    missingFieldsMessage(title, message) {
      this.swal({
        title: title || "Missing fields",
        text: message || "Please provide the required information",
        icon: "warning",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";
@import "@/assets/scss/components/Checkboxes.scss";

.keys-modal {
  &__button {
    @include primary-button($heightSize: 40px);
    @include font-button-text;
    min-width: 150px;
    padding: 5px 3%;
    font-weight: 400;
    i {
      font-size: 16px;
    }
  }
  @include font-standart-text;
  &__header {
    display: flex;
  }
  &__modal-title {
    @include modals-title;
    color: $color-border-container;
  }
  &__close-button {
    text-align: right;
  }
  &__instruction {
    text-align: left;
  }
  &__input {
    &:focus {
      outline: none;
    }
    &--searcher {
      margin-bottom: 15px;
      margin-top: 20px;
    }
  }
}

.button-toolbar {
  display: flex;
  justify-content: space-around;
  &__save {
    @include primary-button;
    background-color: $color-save-button;
    padding: 7px;
    width: 47.5%;
    margin: 0px;
    &:hover {
      background-color: $color-save-button;
    }
  }
  &__cancel {
    @include opposite-secondary-button;
    @include secondary-button();
    outline: 0;
    padding: 7px;
    width: 47.5%;
  }
}

.webhook {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  &__status {
    display: flex;
  }
}

::v-deep {
  .rc-suggestions-list__item {
    color: $color-primary-company;
  }

  .rc-input-field {
    width: 330px;
  }
  .el-checkbox__input {
    background-color: $color-primary-trigger-button;
    border-color: $color-border-container;
    padding: 9px;
  }
}
</style>
