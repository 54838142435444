<template>
  <div class="status-cell-render">
    <el-switch v-model="value" @change="handleSwitchChange"> </el-switch>
  </div>
</template>

<script>
export default {
  name: "StatusCellRender",
  props: {
    cellValue: Object,
  },
  data() {
    return {
      value: false,
    };
  },
  watch: {
    cellValue: {
      handler() {
        this.value = this.cellValue.is_active;
      },
      immediate: true,
    },
  },
  methods: {
    handleSwitchChange() {
      this.$emit("keyUpdated", {
        key: "is_active",
        value: this.value,
        id: this.cellValue.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-cell-render {
  display: flex;
  justify-content: center;
}

::v-deep {
  .active {
    span {
      border-color: $color-border-container !important;
      background-color: $color-border-container !important;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: $color-border-container !important;
    background-color: $color-border-container !important;
  }
}
</style>
