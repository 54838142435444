<template>
  <div
    v-click-outside="hide"
    v-on:touchstart="startLongPress"
    v-on:touchend="stopLongPress"
    @dblclick="toogleEditMode(true)"
    class="email-cell-render"
  >
    <button
      v-if="!editMode && enableCopy && value"
      title="Copy"
      class="email-cell-render__tooltip-icon"
      @click="copyText(cellValue[field])"
    >
      <i class="ion ion-copy-outline"></i>
    </button>
    <div v-if="editMode" class="cell-input-container">
      <textarea
        @keydown.enter="toogleEditMode(false)"
        @input="filterNonNumeric"
        v-model="value"
        class="cell-input-container__input"
        name=""
        id=""
        cols="30"
        rows="2"
        ref="cellInput"
      ></textarea>
      <button class="cell-input-container__input-save" @click="toogleEditMode(false)">
        <i class="ion ion-checkmark-outline"></i>
      </button>
    </div>
    <div v-else class="cell-text-container">
      <p class="cell-text-container__text" type="text">{{ value }}</p>
    </div>
  </div>
</template>

<script>
import ClickOutside from "../../../../directives/ClickOutside";

export default {
  name: "EmailCell",
  props: {
    cellValue: Object,
    field: String,
    onlyNumbers: {
      type: Boolean,
      default: false,
    },
    enableEdit: {
      type: Boolean,
      default: false,
    },
    enableCopy: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    "click-outside": ClickOutside,
  },
  data() {
    return {
      value: "",
      editMode: false,
      previousValue: "",
      longPressTimer: null,
    };
  },
  watch: {
    cellValue: {
      handler() {
        this.value = this.cellValue[this.field];
        this.previousValue = this.cellValue[this.field];
      },
      immediate: true,
    },
  },
  methods: {
    startLongPress() {
      this.longPressTimer = setTimeout(() => {
        this.toogleEditMode(true);
      }, 600);
    },
    stopLongPress() {
      if (this.longPressTimer) {
        clearTimeout(this.longPressTimer);
        this.longPressTimer = null;
      }
    },
    filterNonNumeric() {
      if (!this.onlyNumbers) {
        return;
      }
      this.value = this.value.replace(/[^0-9]/g, "");
    },
    toogleEditMode(value) {
      if (!this.enableEdit) {
        return;
      }
      this.editMode = value;
      if (!value && this.value !== this.previousValue) {
        this.saveChanges();
      }
      this.$nextTick(() => {
        if (value) {
          this.$refs.cellInput.focus();
        }
      });
    },
    saveChanges() {
      this.previousValue = this.value;
      this.$emit("keyUpdated", { key: this.field, value: this.value, id: this.cellValue.id });
    },
    copyText(value) {
      navigator.clipboard.writeText(value);
      this.$message({
        type: "success",
        message: "Copied",
        duration: 1000,
      });
    },
    hide() {
      if (this.editMode) {
        this.editMode = false;
        this.value = this.previousValue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.email-cell-render {
  display: flex;
  align-items: center;
  &__tooltip-icon {
    border: none;
    background-color: inherit;
    color: $color-primary-company;
    padding: 0;
    font-size: 12px;
  }
}

.cell-text-container {
  width: 100%;
  &__text {
    margin: 0px;
    margin-left: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.cell-input-container {
  position: absolute;
  display: flex;
  width: 200px;
  box-shadow: 2px 2px 5px 1px rgb(75, 75, 75);
  background-color: white;
  z-index: 10;
  &__input {
    width: 100%;
    border: none;
    background: none;
    margin-left: 2px;
    padding-right: 5px;
    outline: none;
    resize: none;
  }
  &__input-save {
    border: none;
    background: none;
    font-size: 15px;
    cursor: pointer;
  }
}
</style>
