<template>
  <div class="background-tender-cell">
    <b-form-checkbox
      class="background-tender-cell__checkbox"
      v-model="value"
      @change="checkboxHandler($event)"
    />
  </div>
</template>

<script>
export default {
  name: "BackgroundTenderCell",
  props: {
    cellValue: Object,
    field: String
  },
  data() {
    return {
      value: false,
    };
  },
  watch: {
    cellValue: {
      handler() {
        this.value = this.cellValue[this.field];
      },
      immediate: true,
    },
  },
  methods: {
    checkboxHandler() {
      this.$emit("keyUpdated", {
        key: this.field,
        value: this.value,
        id: this.cellValue.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/Checkboxes.scss";

.background-tender-cell {
  display: flex;
  justify-content: center;
  &__checkbox {
    cursor: pointer;
  }
}
</style>
